.zako {
  /* display: inline-block;*/
  margin: 0%;
  padding-left: 100%;
  white-space: nowrap;
  animation: zako-animate 15s linear infinite;
}
@keyframes zako-animate {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-115%, 0);
  }
}
